import React from 'react';
import './styles.scss';

function App({ data }) {
  return data.length === 0 ? (
    <h1>Could not load leaderboard data.</h1>
  ) : (
    <div className="app">
      <header>
        <h1>Leaderboard: Top 50</h1>
        <p>
          Data is updated at the top of the hour starting from 6/16/24 at 12 AM
          ET &ndash; TODAY.
        </p>
      </header>
      <main>
        <table>
          <thead>
            <tr>
              <th>Ranking</th>
              <th>ID</th>
              <th>Total Sales</th>
              {/* <th>Earnings</th> */}
            </tr>
          </thead>
          <tbody>
            {data.map(({ id, totalSales, commission }, index) => (
              <tr key={id}>
                <th data-label="Ranking">{index + 1}</th>
                <td data-label="ID">{id}</td>
                <td data-label="Total Sales">${totalSales}</td>
                {/* <td data-label="Earnings">${commission}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}

export default App;
