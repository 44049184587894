import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const parseOffer = async () =>
  document.location.search
    .slice(1) // no "?"
    .split('&')
    .map((x) => x.split('='))
    .find(([key]) => key === 'offer')[1];

const fetchData = (offer) =>
  window
    .fetch(process.env.REACT_APP_API_HOST + offer)
    .then((response) => (response.ok ? response.json() : []));

const renderApp = (data) => {
  ReactDOM.render(
    <React.StrictMode>
      <App data={data} />
    </React.StrictMode>,
    document.getElementById('root')
  );
};

const run = () =>
  parseOffer()
    .then(fetchData)
    .catch(() => [])
    .then(renderApp);

run();
